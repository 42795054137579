import React from "react";
import styles from "./About.module.css";

function About() {
  return (
    <section className="section" id="about">
      <header className="section__heading">
        <p className="section__desc">My info</p>
        <h2 className="section__title">About Me</h2>
      </header>
      <div className={styles.wrap}>
        <p className={styles.content}>
          Xin chào! Tôi là Quang Trường. Tôi 19 tuổi. 
          Tôi có ước mơ thành một lập trình viên và tôi đang thực hiện điều đó.
          Kiến thức hiện tại của tôi không quá nhiều và tôi cũng đang cố gắn để
          tiếp thu nhiều kiến thức hơn. Mong rằng những sản phẩm của tôi sẽ làm
          hài lòng mọi người. Xin cảm ơn!
        </p>
        <a href="#contact" className={styles.btn__contact}>
          Contact Me
        </a>
      </div>
    </section>
  );
}

export default About;
